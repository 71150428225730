import React from 'react';
import "./Footer.css";

function Footer() {
    return (
        <div className="footer">
            <p>Copyright © a jsohnfile 2020</p>
            <a href="mailto:jsohnfile@gmail.com">jsohnfile@gmail.com</a>
        </div>
    )
}

export default Footer
